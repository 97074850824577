import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Markdown from 'react-markdown'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DartHeading from './icons/DartHeading'
import { replaceAll } from './utils/replace'

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 12, // Half the value of the grid spacing
    backgroundColor: theme.palette.grey[200],
  },
  container: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  sectionContent: {
    fontFamily: theme.typography.fontFamily,
    "& h1, h2": {
      ...theme.typography.h5,
    },
    "& h3, h4, h5": {
      ...theme.typography.h6,
    },
    "& p": {
      margin: theme.spacing.unit * 2,
    },
  },
  iconText: {
    width: 400,
    "& #line": {
      fill: "transparent",
    },
    "& #mainText": {
      "alignment-baseline": "middle",
      fontFamily: theme.typography.fontFamilySecond,
      fill: theme.palette.primary.light,
      fontSize: 64,
      fontWeight: 700,
      "text-anchor": "middle",
    },
    "& #dart": {
      fill: theme.palette.common.black,
    },
    "& #dart_2": {
      fill: theme.palette.primary.dark,
    }
  }
});

class Services extends React.Component {
  render() {
    const { classes, id, data } = this.props

    const { legal: { replacements, privacy_policy } } = data
    const { heading, disclaimer, updated } = privacy_policy
    const items = replaceAll(privacy_policy.items, replacements)

    return (
      <div className={classes.root}>

        <Grid id={id || "privacy-grid"} container justify="center" spacing={24}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <DartHeading viewBox="0 250 800 300" className={classes.iconText} iconText={heading} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.container} justify="center" spacing={32}>
              <Grid item className={classes.sectionContent} xs={12} lg={10}>
                <Markdown source={replaceAll(disclaimer, replacements)} />
              </Grid>
              {items && items.filter(t => !t.hidden).map((data, i) => {
                return (
                  <Grid key={i} item className={classes.sectionContent} xs={12} lg={10}>
                    <Typography variant="h2">{data.title}</Typography>
                    <Markdown source={data.content} />
                  </Grid>
                )
              })
              }
            </Grid>
          </Grid>

          <Grid className={classes.sectionContent} item xs={12}>
            Updated: {new Date(updated).toString()}
          </Grid>
        </Grid>

      </div>
    )
  }
}

Services.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

export default withStyles(styles)(Services)
