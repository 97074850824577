import React from 'react'
import PropTypes from 'prop-types'

class DartHeading extends React.Component {
  render() {
    const { className, iconText, viewBox, dartColor, dartColor2 } = this.props
    const viewBoxValue = viewBox || "0 0 800 800"
    const backgroundColor = dartColor || "#000000"
    const backgroundColorBack = dartColor2 || "#405567"
    return (
      <svg className={className} viewBox={viewBoxValue}  xmlns="http://www.w3.org/2000/svg">
        <path id="dart_2"  fill={backgroundColorBack} d="M40,400.761c67.002-42.076,158.158-94.812,211.075-125.561 C420.801,316.595,590.989,359.77,760,398.457c-163.685,41.427-341.243,83.968-510.143,124.719 C185.317,484.89,113.442,443.145,40,400.761z"/>
        <path id="dart" fill={backgroundColor} d="M760,397.614c-67.002,42.076-158.158,94.812-211.075,125.562 C379.199,481.78,209.011,438.605,40,399.918c163.684-41.427,341.243-83.969,510.142-124.719 C614.683,313.484,686.559,355.229,760,397.614z"/>
        <path id="line" fill="none" d="M 145,398 L 745,398"/>
        <text id="mainText" x="400" y="400">{iconText}</text>
      </svg>
      )
  }
}

DartHeading.propTypes = {
  className: PropTypes.string,
}

export default DartHeading