import React from "react"
import { StaticQuery, graphql } from "gatsby"

import PrivacySection from "./PrivacySection"

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legal {
          replacements {
            key
            value
          }
          privacy_policy {
            heading
            disclaimer
            updated
            items {
              title
              content
            }
          }
        }
      }
    `}
    render={data => <PrivacySection data={data} {...props} />}
  />
)
