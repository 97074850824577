export const replaceAll = (orig, arrayKeyValue) => {
  if (typeof orig === "string") {
    arrayKeyValue.map(rep => orig = orig.split(`{{${rep.key}}}`).join(rep.value))
    return orig
  }
  return orig.map(item => {
    arrayKeyValue.map(rep => item.content = item.content.split(`{{${rep.key}}}`).join(rep.value))
    return {
      title: item.title,
      content: item.content
    }
  })
}
