import React from 'react'
import Layout from '../components/layout'

import { withStyles } from '@material-ui/core/styles';
import PrivacySection from '../components/PrivacyQuery'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class PrivacyPage extends React.Component {
  render() {
    // store up the array of images fluid value from imageSharp
    const { classes } = this.props
    return (
      <Layout
        className={classes.root}
        location={this.props.location}
        pagedata={{ title: "Legal Stuff", description: "Boring. But important, not impotent." }}
      >
      <PrivacySection />
      </Layout>
    )
  }
}

export default withStyles(styles)(PrivacyPage)
